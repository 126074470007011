/* You can add global styles to this file, and also import other style files */
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'assets/sass/libs/datatables.scss';
@import 'assets/sass/libs/datepicker.scss';
@import 'assets/sass/libs/select.scss';
// ... or
//@import "~@ng-select/ng-select/themes/material.theme.css";


/* Preferably put any custom styles for ngx-datatable into your own "_datatable.scss" file
   and @import it into global styles, but this is a simple working example */

.ngx-datatable {
  border: 1px solid #dddddd;
}

/* File input / File uploader */
.custom-dropzone {
  border: dotted 3px lightgray;
  background-color: #f5f7fa !important;
}

.file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.text-alert {
  font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue, Helvetica, Arial', sans-serif;
  font-size: xx-large;
  color: white !important;
}

.text-ci {
  font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue, Helvetica, Arial', sans-serif;
  font-size: xx-large;
  color: #2f8be6 !important;
}

/** Cadre d'affichage d'une image. */
.img-card {
  max-height: 225px;
  max-width: 225px;
  width: 225px;
  height: 225px;
  border: var(--gray);
  border-width: 2px;
  border-style: dotted;
}


input:required:invalid {
  border-left-color: red;
  border-left-width: 3px;
}

input:required:valid {
  border-left-color: green;
  border-left-width: 3px;
}

textarea[required]:invalid {
  border-left-color: red;
  border-left-width: 3px;
}

textarea[required]:valid {
  border-left-color: green;
  border-left-width: 3px;
}

